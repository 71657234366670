import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import * as decode from 'jwt-decode';
import {CONFIG} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PenalitesRetenu} from '../gestion-retenu/penalites';

@Injectable()
export class GestionPenalitesService {
  url5: string = CONFIG.URL + CONFIG.apiPath;

  constructor(private _http: HttpClient, private http: HttpClient) {
  }

  getAll(): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;

    const url = CONFIG.URL + CONFIG.apiPath + 'penalites/exclusbyetablissement' + '?username=' + username;
    return this._http.get<any>(url, {headers: headers});
  }

  getAllretenu(): Observable<PenalitesRetenu[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;

    const url = CONFIG.URL + CONFIG.apiPath + 'penalites/retenubyetablissement' + '?username=' + username;
    return this._http.get<any>(url, {headers: headers});
  }

  getAllavertissement(): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;

    const url = CONFIG.URL + CONFIG.apiPath + 'penalites/avertissementbyetablissement' + '?username=' + username;
    return this._http.get<any>(url, {headers: headers});
  }

  getPdf(id: number): Observable<Blob> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get('http://localhost:8080/pdf/pdfretenu' + '?id=' + id, {responseType: 'blob', headers: headers});
  }

  exclusPdf(id: number): Observable<Blob> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get('http://localhost:8080/pdf/pdfexclus' + '?id=' + id, {responseType: 'blob', headers: headers});
  }

  avertissementPdf(id: number): Observable<Blob> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get('http://localhost:8080/pdf/pdfavertissement' + '?id=' + id, {responseType: 'blob', headers: headers});
  }

  archiveExclus(id_penalites: any): any {
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'penalites/archiversexclus' + '?username=' + username;
    return this._http.post(url, id_penalites, {headers: headers});

  }

  archiveRetenu(id_penalites: any): any {
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'penalites/archiverretenu' + '?username=' + username;
    return this._http.post(url, id_penalites, {headers: headers});
  }

  archiveAverti(id_penalites: any): any {
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'penalites/archiveraverti' + '?username=' + username;
    return this._http.post(url, id_penalites, {headers: headers});

  }


}
