import { Message } from "./message";
import { Observable } from "rxjs";
import { decode } from "punycode";
import { CONFIG } from "../../../environments/environment";
import { Eleve } from "../gestion-eleves/eleve";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class BoiteLettreService {


    constructor(private _http: HttpClient, private http: HttpClient) {

    }



    url: string = CONFIG.URL + CONFIG.apiPath + 'eleve/all';

    getAllEleves(): Observable<Eleve[]> {
        var decoded = decode(localStorage.getItem('token'));
        var username = decoded.sub;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        let url = CONFIG.URL + CONFIG.apiPath + 'eleve/allbyetablissement' + '?username=' + username;
        return this._http.get<Eleve[]>(url, { headers: headers });
    }

    url1: string = CONFIG.URL + CONFIG.apiPath + 'messages/recue';

    getMessageRecu(username: any): Observable<Message[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        let url1 = CONFIG.URL + CONFIG.apiPath + 'messages/recue' + '?username=' + username;
        return this._http.get<Message[]>(url1, { headers: headers });
    }

    getMessageAnnuler(username: any): Observable<Message[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        let url1 = CONFIG.URL + CONFIG.apiPath + 'messages/etatannuler' + '?username=' + username;
        return this._http.get<Message[]>(url1, { headers: headers });
    }
    getMessageArchiver(username: any): Observable<Message[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        let url1 = CONFIG.URL + CONFIG.apiPath + 'messages/afficherarchiversender' + '?username=' + username;
        return this._http.get<Message[]>(url1, { headers: headers });
    }


    getMessageEnvoyer(username: any): Observable<Message[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        let url102 = CONFIG.URL + CONFIG.apiPath + 'messages/envoyer' + '?username=' + username;
        return this._http.get<Message[]>(url102, { headers: headers });
    }

    getMessgDetail(id: any): Observable<any> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        let url102 = CONFIG.URL + CONFIG.apiPath + 'messages/messagebyid' + '?id=' + id;
        return this._http.get<Message[]>(url102, { headers: headers });
    }




    archiver(mesg: any, username): any {
        let url = CONFIG.URL + CONFIG.apiPath + 'messages/archiversender' + '?username=' + username;
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
      return this._http.post<any>(url, mesg, { headers: headers });
    }
    delete(mesg, username): any {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        let url = CONFIG.URL + CONFIG.apiPath + 'messages/delete' + '?username=' + username;
        return this.http.request('delete', url, { body: mesg , headers: headers});
    }

    


}

