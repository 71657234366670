import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CONFIG } from 'src/environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
 
import * as decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class GestionFormuleTrimestreService {


  constructor(private _http: HttpClient) { }
  

  addFormuleTrimestre(formuleTrimestre, trimestre): Observable<any[]> {
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'formuleTrimestre/add' + '?trimestre=' + trimestre;
    return this._http.post<any>(url,formuleTrimestre , { headers: headers });
}

getAllFormuleTrimestres(): Observable<any[]> {
  const decoded = decode(localStorage.getItem('token'));
  const username = decoded.sub;

  const url = CONFIG.URL + CONFIG.apiPath + 'formuleTrimestre/all';
const token = localStorage.getItem('token');
const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
  return this._http.get<any>(url, { headers: headers });
}

}

