import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { CONFIG } from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class SemaineService {
    constructor(private _http: HttpClient) {

    }
    url5: string = CONFIG.URL + CONFIG.apiPath + 'salle/remove';

    // Add  user
    url2: string = CONFIG.URL + CONFIG.apiPath + 'annee/addsemaine';
    url22: string = CONFIG.URL + CONFIG.apiPath + 'annee/updateSemaine';
    url25: string = CONFIG.URL + CONFIG.apiPath + 'jour/getall';

    url251: string = CONFIG.URL + CONFIG.apiPath + 'user/profil';


    url: string = CONFIG.URL + CONFIG.apiPath + 'annee/allsemaine';

    url4: string = CONFIG.URL + CONFIG.apiPath + 'annee/allsemaine';
    deleteSalle(nomSalle: any): any {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post(this.url5, nomSalle, { headers: headers })
            ;
    }

    addsemaine(semaine: string, datedebut: any, datefin: any): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(this.url2, ({
            semaine: semaine, datedebut: datedebut, datefin: datefin
        } || null)
            , { headers: headers })  ;
    }

    updateSemaine(semaine: string, datedebut: any, datefin: any): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.put<any>(this.url22, ({
            semaine: semaine, datedebut: datedebut, datefin: datefin
        } || null)
            , { headers: headers })  ;
    }

    getAllJour(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(this.url, { headers: headers });
    }

    getAllUser(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(this.url, { headers: headers });
    }

    getAllSemaine(): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.get<any>(this.url, { headers: headers });
    }

    editSalle(nomSalle: string, type: string, remarque: string): Observable<any[]> {
      const token = localStorage.getItem('token');
      const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
        return this._http.post<any>(this.url4, {
            nomSalle: nomSalle, type: type, remarque: remarque
        } || null, { headers: headers })           ;
    }
}
