import { Injectable } from '@angular/core';
import * as decode from 'jwt-decode';
import { CONFIG } from '../../../environments/environment';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {HttpClient, HttpHeaders} from '@angular/common/http';



@Injectable({
  providedIn: 'root'
})
export class DirecteurstatistiqueService {


  constructor(private _http: HttpClient, private https: HttpClient) { }



  getallstatistiques(): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const decoded = decode(token);
    const username = decoded.sub;
    const url = CONFIG.URL + CONFIG.apiPath + 'dashboard/etat' + '?username=' + username;
    return this._http.get<any>(url, { headers: headers });
  }

  sendNotifParent(id: any): any {
    const url =
      CONFIG.URL +
      CONFIG.apiPath +
      'Parrain/sendEmailParrainEleveProfAbscent?id=' + id;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);    return this._http
      .post(url, { headers: headers });
  }
}
