import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { CONFIG } from '../../../../environments/environment';

import * as decode from 'jwt-decode';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CahierScolaire } from './CahierScolaire';

@Injectable({
  providedIn: 'root'
})
export class CahierScolaireService {

  constructor(private _http: HttpClient) { }



  getAllCahier(classe): Observable<any[]> {
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'cahierscolaire/getallbyclasseByprof' + '?classe=' + classe+'&username='+username;
    return this._http.get<any>(url, { headers: headers });
  }

  getCahierById(id:number):Observable<any>{
    const decoded = decode(localStorage.getItem('token'));
    const username = decoded.sub;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL+CONFIG.apiPath+'cahierscolaire/get/'+id;
    return this._http.get<any>(url,{headers: headers})
  }




  add(id: string, titre: string, texte: string): Observable<any[]> {
    const url2: string = CONFIG.URL + CONFIG.apiPath + 'cahierscolaire/add?idprogramme=' + id;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    console.log(url2, ({
      note: titre, remarque: texte
    }));
    return this._http
      .post<any>(
        url2, ({
          note: titre, remarque: texte
        } || null
        ),
        { headers: headers }
      );
  }

  editCahier(cahier:CahierScolaire): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const url = CONFIG.URL + CONFIG.apiPath + 'cahierscolaire/put/'+cahier.id
    return this._http.put<any>(url,cahier, { headers: headers });
}


updateCahierSco(id, data): Observable<any[]> {
 
  const token = localStorage.getItem('token');
  const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
   const url4 = CONFIG.URL + CONFIG.apiPath + 'cahierscolaire/cahierSco/'+id;
    return this._http.put<any>(url4, data, { headers: headers });
}


}
