export class Penalites {



    public id_penalites : any ;
    public numInscription : any;
    public remarque : any  ;
    public archiver:boolean;
    public  date_Penalites : Date;
   
    constructor() {}


    
 
 
 }