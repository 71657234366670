import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { CONFIG } from '../../../environments/environment';
import * as decode from 'jwt-decode';
import { map } from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TravailDemandeEleveService {

  constructor(private _http: HttpClient) { }



  url6: string;
  getUserbyusername(username: string): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    this.url6 = CONFIG.URL + CONFIG.apiPath + 'user/getbyusername' + '?username=' + username;
    let a: any;
    return a = this._http.get(this.url6, { headers: headers });
  }


  getAllTravEleve(username, classeActuel, TrimestreActuel): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    let urlns = CONFIG.URL + CONFIG.apiPath + 'ulpoadTravail/getTDbyClasse/?numInscrit=' + username + '&nomClasse=' + classeActuel + '&nomTrimestre=' + TrimestreActuel;
    return this._http.get<any>(urlns, { headers: headers });
  }

  getMatByClasse(classeActuel): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    let urlns = CONFIG.URL + CONFIG.apiPath + 'emplois/getAllMatiereByClasse?nomClasse=' + classeActuel;
    // console.log(urlns);
    return this._http.get<any>(urlns, { headers: headers });
  }

  getTrimestreActuel(): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    let urlns = CONFIG.URL + CONFIG.apiPath + 'ulpoadTravail/getTrimestreActuel';
    // console.log(urlns);
    return this._http.get<any>(urlns, { headers: headers });
  }


  getAllReponseEleve(username, idDoc): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    let urlns = CONFIG.URL + CONFIG.apiPath + 'ulpoadTravail/getTDbyProfAndClasse/' + '?userconnect='
      + username + '&nomClasse=' + idDoc;
    // console.log(urlns);
    return this._http.get<any>(urlns, { headers: headers });
  }

  // http://207.180.211.159:8080/DSMS-0.0.1-SNAPSHOT/api/63513
  getAllCorrection(idDoc): Observable<any[]> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    let urlns = CONFIG.URL + CONFIG.apiPath + 'ulpoadTravail/getCorrigeOfTD?idTD=' + idDoc;
    return this._http.get<any>(urlns, { headers: headers });
  }

}
